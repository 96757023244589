<template>
    <div>
        <b-button v-b-modal.modal-1 block variant="danger">FLUSH ALL THE CATALOG</b-button>

        <b-modal id="modal-1" title="BootstrapVue" @ok="flush">IS THIS YOUR VERY LAST WORD ?</b-modal>

        <div v-if="message!=''" class="alert alert-info" role="alert">{{ message }}</div>
    </div>
</template>

<script>
	export default {
		name: "FlushDemonstrations",
		data() {
			return {
				message: ""
			};
		},
		methods: {
			flush() {
				/*DemonstrationsApi.flush()
					.then(response => {
						this.message = "All the catalog has been deleted";
					})
					.catch(e => {
						console.error(e);
						this.message = e;
					});*/
			}
		}
	};
</script>